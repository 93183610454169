<template>
  <div>
      <div class="card">
          <div class="card-header">
              <div class="card-title m-0">
                <h3>Takvim</h3>
                </div>
          </div>
          <div class="card-body">
              <div class="row">
                  <div class="col-lg-6">
                      <FullCalendar ref="calendar" :events="events" :options="calendarOptions" class="demo-app-calendar" @clickDate="handleDateClick"></FullCalendar>
                  </div>
                  <div class="col-lg-6">
                      <div id="times-list">
                                <div class="fw-bolder mb-10" v-if="selectedDate">Tarih: {{ moment(selectedDate.dateStr).format('DD MMMM YYYY, dddd') }}</div>
                                <div class="d-flex flex-wrap">
                                    <div v-for="time in times" :key="time" class="col-lg-2 mb-5">
                                        <label>
                                            
                                            <input type="checkbox" @change="selectTime($event)" name="time[]" :value="time" 
                                            :checked="(selectedDate && this.eventList[this.selectedDate.dateStr] && this.eventList[this.selectedDate.dateStr].activeTimes.includes(time))">
                                            {{time}}
                                        </label>                              
                                    </div>
                                </div>

                                <div class="row border-top pt-4 d-flex w-100 justify-content-between">
                                    <div style="width:auto !important">
                                        <button v-show="false" class="btn btn-warning me-2" @click.prevent="applyLastSelectedDate()">Bir önceki seçimi uygula</button>
                                        <button v-show="false" class="btn btn-danger" @click.prevent="clearSelectedDate()">Seçimi sıfırla</button>

                                    </div>
                                    <div  style="width:auto !important"><button @click.prevent="saveTimes()" class="btn btn-success">Kaydet</button></div>
                                </div>


                            </div>
                  </div>


                 
              </div>
              
          </div>
      </div>

      
    
  </div>
</template>


  <script>
    
    import FullCalendar from '@fullcalendar/vue3'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import moment from 'moment'
    import api from '@/core/services/ApiService'
    import Swal from "sweetalert2/dist/sweetalert2.js";

    export default {
        name:'DoctorCalendar',
        props:['profile'],
        components:{
            FullCalendar
        },
        data(){
            return {
                selectedDate:{dateStr:moment().format('YYYY-MM-DD')},
                lastSelectedDate:{dateStr:moment().format('YYYY-MM-DD')},
                events:[],
                eventList:{},
                times:[],
                calendarOptions: {
                    plugins: [ dayGridPlugin, interactionPlugin ],
                    initialView: 'dayGridMonth',
                    dateClick: this.handleDateClick,
                }
            }
        },

        methods:{

            removeBlanks(){
                for(let i of Object.entries(this.eventList)){
                   let item = i[1];
                   
                   if(item!==undefined && item.activeTimes && item.activeTimes.length==0){
                      delete this.eventList[i[0]];
                   }
                }

                for(let i in this.events){
                    if(this.events[i].title=='0'){
                        this.events.splice(i,1);
                    }
                }
                    
                


            },

            calendarRefetchEvents(){
                let calendar = this.$refs.calendar.getApi();
              //  this.updateEvents();
                this.calendarOptions.events = this.events;
                
                calendar.refetchEvents();
            },


            updateEvents(){
                let i = 0;
                this.events = [];
                for(let i of Object.entries(this.eventList)){
                    let element = i[1];

                    this.events.push({
                        
                        title:element.activeTimes.length,
                        date:element.date
                    });

                    let eventData = element;
                    eventData.key = i;
                    i++;
                  //  this.eventList[element.date] = eventData;
                }
            },


            applyLastSelectedDate(){
                this.eventList[this.selectedDate.dateStr] = this.eventList[this.lastSelectedDate.dateStr];
                this.calendarRefetchEvents();
            },

            clearSelectedDate(){
                delete this.eventList[this.selectedDate.dateStr];
               
                
                this.events.splice(this.events.findIndex((item)=>{
                    return item.date == this.selectedDate.dateStr;
                }),1);

                this.calendarRefetchEvents();

            },
            saveTimes(){
                let times=[];
                for (const [key, value] of Object.entries(this.eventList)) {
                    times.push({Date:value.date, ActiveTimes:value.activeTimes});
                }
                
                api.post('/User/SetDoctorTime',{doctorId:this.profile.userId,listTimes:times}).then((res)=>{
                    Swal.fire({
                        title:'Takvim',
                        text:'Danışman takvimi başarıyla güncellendi.',
                        icon:'success'
                    }).then(()=>{
                        window.location.reload();
                    });
                }).catch((err)=>{
                    console.log(err);
                })
            },
            timesSelected(){
                this.selectedDate=null;
            },
            handleDateClick(info) {

               // this.removeBlanks();
                this.lastSelectedDate = this.selectedDate;

                if(!this.eventList[info.dateStr]){
                    this.eventList[info.dateStr] = {date:info.dateStr, activeTimes:[],key:Object.keys(this.eventList).length};
                     this.events.push({
                        title:"0",
                        date:info.dateStr
                     });
                     
                     this.calendarRefetchEvents();
                }
                this.selectedDate = info;
            },
            startTime(){
                return this.moment().set({hour:0,minute:0,second:0,millisecond:0})
            },
            
            moment(date){
                return moment(date)
            },
            timeStr(i){
                return this.startTime().add((i-1)*30, 'minutes').format('HH:mm');
            },
            selectTime(e){
                let checked = e.target.checked;
                let value = e.target.value;
                let eventItem = this.eventList[this.selectedDate.dateStr] || {activeTimes:[],date:this.selectedDate.dateStr};
                let activeTimes = eventItem.activeTimes;

                let dateKey = eventItem.key;

                if(checked){
                    if(!activeTimes.includes(value)){
                        activeTimes.push(value);
                    } 
                }else{
                    let timeIndex = activeTimes.indexOf(value);
                    if (timeIndex > -1) {
                        activeTimes.splice(timeIndex, 1);
                    }
                }

                this.events[dateKey].title = activeTimes.length;
                this.calendarRefetchEvents();
            }
        },
        
        mounted(){
            api.get('/User/ListTime/'+this.profile.userId).then((res)=>{
                this.times = res.data;
            });
            
            api.get('/User/ListDoctorTime/'+this.profile.userId).then((res)=>{
                console.log(this.profile);
                let data = res;
                let i = 0;
                data.data.forEach(element => {
                    this.events.push({
                        
                        title:element.activeTimes.length,
                        date:element.date
                    });

                    let eventData = element;
                    eventData.key = i;
                    i++;
                    this.eventList[element.date] = eventData;
                });

               this.calendarRefetchEvents();
                
                
                

                
            })

        }

    }
      </script>

